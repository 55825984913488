var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"d-flex flex-column flex-column-fluid new-service",staticStyle:{"width":"calc(90vw - 20%) !important"},attrs:{"id":"f-book-now"}},[(_vm.saveDataLoader)?_c('div',{staticClass:"full-load"},[_c('h1',{domProps:{"innerHTML":_vm._s(_vm.loadingMessage)}})]):_vm._e(),_c('div',{staticClass:"d-flex flex-column-fluid"},[_c('div',{},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12 col-md-12 col-12"},[_c('div',{staticClass:"sticky-t-details"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[(_vm.errorMsg != '')?_c('div',{staticClass:"alert alert-custom alert-danger-dim alert-shadow fade show gutter-b alert-fixed",attrs:{"role":"alert"}},[_c('div',{staticClass:"alert-text"},[_vm._v(" "+_vm._s(_vm.errorMsg)+" ")])]):_vm._e(),_c('h4',{staticClass:"page-info mt-5"},[_vm._v(" Please select a fingerprint service ")])])])]),_c('div',{staticClass:"row mt-1 mb-20"},[(_vm.dataLoading)?_c('div',{staticClass:"col-md-12 order-1 mb-5 text-center"},[_c('loading-spinner',{staticStyle:{"margin-top":"20px"}}),_c('h5',{staticClass:"mt-3"},[_vm._v("Please wait!")])],1):_vm._e(),_vm._l((_vm.filteredServices),function(service,index){return _c('div',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.filteredServices.length > 0 &&
                  !_vm.dataLoading &&
                  _vm.current_page == 1
              ),expression:"\n                filteredServices.length > 0 &&\n                  !dataLoading &&\n                  current_page == 1\n              "}],key:index,staticClass:"col-lg-4 col-md-6 col-sm-12 order-1 mb-3",class:[_vm.selected_index == index ? 'selected-service' : '']},[_c('v-service-card',{attrs:{"front_status":false,"url":_vm.url,"service":service,"index":index},on:{"setserviceindex":_vm.setServiceindex}})],1)}),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12 order-1 mb-3 f-payment"},[(_vm.current_page == 2)?_c('f-payment',{attrs:{"front_status":false}}):_vm._e()],1),_c('div'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.filteredServices.length <= 0 && !_vm.dataLoading),expression:"filteredServices.length <= 0 && !dataLoading"}],staticClass:"col-md-12 order-1 mb-5 text-center"},[_c('v-no-record')],1),_c('div',{staticClass:"bottom-nav",staticStyle:{"position":"fixed","bottom":"20px","width":"calc(74vw)"},attrs:{"id":"book-footer-temp"}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"cleft"},[(
                        _vm.current_page == 2 &&
                          _vm.getfClientRate &&
                          _vm.getServiceTypeCode != 'fdle'
                      )?_c('div',{staticClass:"total_amount"},[_vm._m(0),_c('h3',{staticClass:"footer-total-amount"},[_vm._v(" $"+_vm._s(_vm.getTotalServiceAmount)+" ")])]):(
                        _vm.current_page == 2 &&
                          _vm.getfClientRate &&
                          _vm.getServiceTypeCode == 'fdle' &&
                          _vm.getServiceOriCode.success == true
                      )?_c('div',{staticClass:"total_amount"},[_vm._m(1),_c('h3',{staticClass:"footer-total-amount"},[_vm._v(" $"+_vm._s(_vm.getTotalServiceAmount)+" ")])]):_c('div',{staticClass:"total_amount"},[_c('h3',{staticClass:"footer-total-amount"})])]),(_vm.current_page == 2)?_c('button',{staticClass:"btn btn-success mr-2 mt-3 btn-right",on:{"click":function($event){return _vm.submitService()}}},[_vm._v(" Submit ")]):_vm._e(),_c('button',{staticClass:"btn btn-dark mr-2 mt-3 btn-right",on:{"click":function($event){return _vm.goback()}}},[_vm._v(" Go Back ")])])])])],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_c('small',[_vm._v("Total ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{attrs:{"for":""}},[_c('small',[_vm._v("Total ")])])
}]

export { render, staticRenderFns }