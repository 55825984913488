<style lang="scss">
#service-container-app {
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
  }
}
</style>
<template lang="">
  <div
    class="bg-light-default rounded p-5"
    id="service-container-app"
    @click="
      openService(
        service.slug,
        service.id,
        service.service_type,
        service.name,
        service.is_drug_testing,
        service.is_service_by_mail,
        service.service_type_code,
        service.show_capture_method,
        service.enable_ori_rate
      )
    "
  >
    <div class="d-flex align-items-center">
      <div class="symbol symbol-60 symbol-2by3 flex-shrink-0 mr-4">
        <div
          class="symbol-label"
          :style="
            'background-size: contain;background-image: url(' +
              url +
              service.image +
              ')'
          "
        ></div>
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <a
          class="text-dark-cust font-weight-bolder font-size-lg text-hover-primary-cust mb-1"
          >{{ service.name }}</a
        ><span class="text-dark-50 font-weight-normal font-size-sm">
          {{ service.description }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import {
  SET_SERVICE_NAME,
  SET_APPOINTMENT_TIME,
  SET_APPOINTMENT_DATE,
  SET_SERVICE_ID,
  SET_SERVICE_TYPE,
  SET_F_SERVICE_SLUG,
  SET_IS_DRUG_TESTING,
  SET_IS_SERVICE_BY_MAIL,
  SET_SERVICE_TYPE_CODE,
  SET_SHOW_CAPTURE_OPTION,
  UPDATE_ORI,
  UPDATE_SERVICE_ORI_CODE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  SET_ENABLE_ORI_RATE
} from "@/core/services/store/mutations.type";
export default {
  props: {
    url: {
      type: String,
      default: ""
    },
    index: {
      type: Number,
      default: 0
    },
    front_status: {
      type: Boolean,
      default: true
    },
    service: {
      type: Object,
      default: function() {
        return {
          id: 1,
          name: "Service Title",
          image: "/media/stock-600x400/img-20.jpg",
          description: "Description Here"
        };
      }
    }
  },
  data() {
    return {
      Groupservices: []
    };
  },

  methods: {
    openService(
      slug,
      id,
      serviceType,
      name,
      is_drug_testing,
      is_service_by_mail,
      service_type_code,
      show_capture_method,
      enable_ori_rate
    ) {
      // alert(slug);
      if (this.front_status) {
        this.$router.push({
          name: "frontend.agent.locations",
          params: { slug: slug, client_slug: this.$route.params.client_slug }
        });
        this.$store.commit(SET_SERVICE_NAME, name);
        this.$store.commit(SET_SERVICE_ID, id);
        this.$store.commit(SET_APPOINTMENT_TIME, null);
        this.$store.commit(SET_APPOINTMENT_DATE, "");
        this.$store.commit(SET_SERVICE_TYPE, serviceType);
        this.$store.commit(SET_SERVICE_TYPE_CODE, service_type_code);
        this.$store.commit(SET_ENABLE_ORI_RATE, enable_ori_rate);
        this.$store.commit(SET_IS_DRUG_TESTING, is_drug_testing);
        this.$store.commit(SET_IS_SERVICE_BY_MAIL, is_service_by_mail);
        this.$store.commit(SET_SHOW_CAPTURE_OPTION, show_capture_method);
        this.$store.commit(UPDATE_ORI, "");
        //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);

        this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
        this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
          data: "",
          success: "none"
        });

        // alert(this.$route.params.slug);
        this.$store.commit(SET_F_SERVICE_SLUG, slug);
      } else {
        // console.log("afkj");
        this.$store.commit(SET_SERVICE_ID, id);
        this.$store.commit(SET_SERVICE_TYPE_CODE, service_type_code);
        this.$emit("setserviceindex", this.index);
      }
    }
  }
};
</script>
