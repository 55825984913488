<template>
  <section
    class="d-flex flex-column flex-column-fluid new-service"
    id="f-book-now"
    style="width: calc(90vw - 20%) !important"
  >
    <div class="full-load" v-if="saveDataLoader">
      <h1 v-html="loadingMessage"></h1>
    </div>
    <div class="d-flex flex-column-fluid">
      <div class="">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="sticky-t-details">
              <div class="row">
                <div class="col-12">
                  <div
                    v-if="errorMsg != ''"
                    role="alert"
                    class="
                      alert alert-custom alert-danger-dim alert-shadow
                      fade
                      show
                      gutter-b
                      alert-fixed
                    "
                  >
                    <div class="alert-text">
                      {{ errorMsg }}
                    </div>
                  </div>
                  <h4 class="page-info mt-5">
                    Please select a fingerprint service
                  </h4>
                </div>
              </div>
            </div>
            <div class="row mt-1 mb-20">
              <div
                class="col-md-12 order-1 mb-5 text-center"
                v-if="dataLoading"
              >
                <loading-spinner style="margin-top: 20px"> </loading-spinner>
                <h5 class="mt-3">Please wait!</h5>
              </div>
              <div
                class="col-lg-4 col-md-6 col-sm-12 order-1 mb-3"
                v-for="(service, index) in filteredServices"
                :key="index"
                v-show="
                  filteredServices.length > 0 &&
                    !dataLoading &&
                    current_page == 1
                "
                :class="[selected_index == index ? 'selected-service' : '']"
              >
                <v-service-card
                  :front_status="false"
                  :url="url"
                  :service="service"
                  :index="index"
                  @setserviceindex="setServiceindex"
                ></v-service-card>
              </div>
              <div class="col-lg-12 col-md-12 col-sm-12 order-1 mb-3 f-payment">
                <f-payment
                  v-if="current_page == 2"
                  :front_status="false"
                ></f-payment>
              </div>
              <div></div>
              <div
                class="col-md-12 order-1 mb-5 text-center"
                v-show="filteredServices.length <= 0 && !dataLoading"
              >
                <v-no-record></v-no-record>
              </div>
              <div
                id="book-footer-temp"
                class="bottom-nav"
                style="position: fixed; bottom: 20px; width: calc(74vw)"
              >
                <div class="card">
                  <div class="card-body">
                    <div class="cleft">
                      <div
                        class="total_amount"
                        v-if="
                          current_page == 2 &&
                            getfClientRate &&
                            getServiceTypeCode != 'fdle'
                        "
                      >
                        <label for="">
                          <small>Total </small>
                        </label>
                        <h3 class="footer-total-amount">
                          ${{ getTotalServiceAmount }}
                        </h3>
                      </div>
                      <div
                        class="total_amount"
                        v-else-if="
                          current_page == 2 &&
                            getfClientRate &&
                            getServiceTypeCode == 'fdle' &&
                            getServiceOriCode.success == true
                        "
                      >
                        <label for="">
                          <small>Total </small>
                        </label>
                        <h3 class="footer-total-amount">
                          ${{ getTotalServiceAmount }}
                        </h3>
                      </div>
                      <div class="total_amount" v-else>
                        <h3 class="footer-total-amount"></h3>
                      </div>
                    </div>
                    <button
                      v-if="current_page == 2"
                      class="btn btn-success mr-2 mt-3 btn-right"
                      @click="submitService()"
                    >
                      Submit
                    </button>
                    <button
                      class="btn btn-dark mr-2 mt-3 btn-right"
                      @click="goback()"
                    >
                      Go Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import loadingSpinner from "@/components/frontend/spinner.vue";
import vServiceCard from "@/components/frontend/groupservicecard";
import vNoRecord from "@/components/frontend/norecord";
import fPayment from "@/pages/frontend/booknow/payment.vue";
import {
  SET_BREADCRUMB,
  SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
  UPDATE_ABBREVIATION_CODE,
  SET_PAYMENT_CARD_PROPERTY,
  SET_PAYMENT_CARD_TYPE,
  SET_PAYMENT_CARD_NUMBER,
  SET_PAYMENT_CARD_HOLDER_NAME,
  SET_PAYMENT_CARD_ADDRESS,
  SET_PAYMENT_CARD_CITY,
  SET_PAYMENT_CARD_CVV_NUMBER,
  SET_PAYMENT_CARD_EXPIRY_DATE,
  CARD_COUNTRY,
  CARD_STATE,
  SET_F_CLIENT_RATE,
  UPDATE_PAYMENT_TYPE,
  SET_PAYMENT_CARD_NUMBER_PHONE,
  SET_PAYMENT_CARD_NUMBER_POSTAL,
  SET_NEW_APP_NO,
  SET_RATE_TOTAL_AMOUNT,
  SET_ADD_RATE_QTY,
  UPDATE_SERVICE_ORI_CODE,
  SET_CHECK_F_SERVICE_ORI_CODE,
  UPDATE_ORI,
  SET_ADDITIONAL_CARD_TOTAL_AMOUNT,
  SET_TOTAL_SERVICE_AMOUNT,
  SET_ENABLE_ORI_RATE,
  SET_SERVICE_TYPE_CODE
} from "@/core/services/store/mutations.type";

import {
  LOCATION_SERVICE,
  FETCH_F_CLIENT_RATE,
  NEW_APPOINTMENT,
  STORE_APPOINTMENT_FROM_APPLICANT,
  MAKE_INVOICE_AFTER_ONLINE_PAYEMENT,
  FETCH_COUNTRIES,
  FETCH_STATES,
  CHECK_F_SERVICE_ORI_CODE
} from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";

export default {
  components: {
    vServiceCard,
    vNoRecord,
    loadingSpinner,
    fPayment
  },
  mounted() {
    // if (this.getServiceId == 0 && this.getServiceId != this.$route.params.id) {
    //   this.$router.go(-1);
    // }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
    this.$store.commit(
      SET_F_CLIENT_ENABLE_ON_SITE_PAYMENT,
      this.$route.params.onsite
    );
    this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);
    this.$store.commit(SET_ADD_RATE_QTY, 0);
    this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
    this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
    this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
    this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
    this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
    this.$store.commit(SET_PAYMENT_CARD_CITY, "");
    this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
    this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
    this.$store.commit(CARD_COUNTRY, "US");
    this.$store.commit(CARD_STATE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
    this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
    this.$store.commit(SET_ENABLE_ORI_RATE, "");
    this.$store.commit(SET_SERVICE_TYPE_CODE, "");
    this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
      data: "",
      success: "none"
    });
    this.fetchServices();
    this.$store.dispatch(FETCH_COUNTRIES);
    this.$store.dispatch(FETCH_STATES);
  },
  data() {
    return {
      service_ori: "",
      service_ori_code_error: false,
      errorMsg: [],
      current_page: 1,
      dataLoading: false,
      services: [],
      url: "",
      searchInput: "",
      selected_index: -1,
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard"
        },
        {
          id: 2,
          title: "Appointment List",
          route: ""
        },
        {
          id: 2,
          title: "New Service",
          route: ""
        }
      ],
      saveDataLoader: false,
      loadingMessage: "",
      rateData: []
    };
  },
  computed: {
    filteredServices() {
      let tempServices = this.services;
      //process search input
      if (this.searchInput != "" && this.searchInput) {
        tempServices = tempServices.filter(item => {
          return item.name
            .toUpperCase()
            .includes(this.searchInput.toUpperCase());
        });
      }
      return tempServices;
    },
    ...mapGetters([
      "getServiceId",
      "getNewAppNo",
      "getTotalRateAmount",
      "getfClientRate",
      "getAbbreviationCode",
      "updateAbbreviationCode",
      "getAddQty",
      "getPaymentCardNumber",
      "getPaymentCardHolderName",
      "getPaymentCardExpiry",
      "getPaymentCardCvvNumber",
      "getPaymentCardType",
      "getSelectedCountryPhoneCode",
      "getPaymentCardHolderAddress",
      "getPaymentCardHolderCountry",
      "getPaymentCardHolderState",
      "getPaymentCardHolderCity",
      "getApplicantDobMonth",
      "getApplicantDobDay",
      "getApplicantDobYear",
      "getNewAppNo",
      "getPaymentCardPhonecode",
      "getPaymentCardPhone",
      "getPaymentCardPostalZip",
      "getPaymentType",
      "getServiceProviderData",
      "getIsHeartsPayment",
      "getTotalServiceAmount",
      "getServiceTypeCode",
      "getServiceOriCode",
      "getServiceOri",
      "updateServiceOriCode",
      "getTotalAdditionalAmount"
    ])
  },
  filters: {
    toFixedTwoDigit(amount, data) {
      var rate = parseFloat(data.rate) * parseInt(data.service.default_qty);
      // var rate = parseFloat(data.rate);
      var tp = parseFloat(data.tax_percentage);
      if (rate > 0) {
        return (parseFloat(amount) + (rate + (rate * tp) / 100)).toFixed(2);
      } else {
        return 0;
      }
    }
  },
  methods: {
    reset() {
      this.$store.commit(UPDATE_ABBREVIATION_CODE, "");
      this.$store.commit(SET_PAYMENT_CARD_PROPERTY, "");
      this.$store.commit(SET_PAYMENT_CARD_TYPE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_HOLDER_NAME, "");
      this.$store.commit(SET_PAYMENT_CARD_ADDRESS, "");
      this.$store.commit(SET_PAYMENT_CARD_CITY, "");
      this.$store.commit(SET_PAYMENT_CARD_CVV_NUMBER, "");
      this.$store.commit(SET_PAYMENT_CARD_EXPIRY_DATE, "");
      this.$store.commit(CARD_COUNTRY, "US");
      this.$store.commit(CARD_STATE, "");
      this.$store.commit(SET_NEW_APP_NO, {
        app_id: "",
        appt_id: "",
        time: "",
        appt_token: "",
        date: ""
      });

      this.$store.commit(SET_F_CLIENT_RATE, {
        rate: "0",
        additional_rate: "0",
        service: {
          default_qty: 0
        }
      });
      this.$store.commit(UPDATE_PAYMENT_TYPE, "C");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_PHONE, "");
      this.$store.commit(SET_PAYMENT_CARD_NUMBER_POSTAL, "");
      this.$store.commit(UPDATE_ORI, "");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);

      this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
    },
    submit() {
      var mytoken = document.getElementById("mytoken");
      mytoken = mytoken.innerHTML;
      if (this.updateAbbreviationCode != "") {
        if (!this.getAbbreviationCode.success) {
          this.$toastr.e(this.getAbbreviationCode.msg);
        } else {
          if (this.getPaymentType == "C") {
            this.loadingMessage =
              "please wait <br> While we process your payment...";

            if (mytoken == "" || mytoken == null) {
              this.$toastr.e(
                "The card number entered is not compliant, please check and re-enter."
              );
            } else {
              if (this.getIsHeartsPayment) {
                var expiremonth = document.getElementById("expiremonth")
                  .innerHTML;
                var expireyear = document.getElementById("expireyear")
                  .innerHTML;

                if (
                  mytoken == "" ||
                  mytoken == null ||
                  expiremonth == "" ||
                  expiremonth == null ||
                  expireyear == "" ||
                  expireyear == null
                  // this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              } else {
                if (
                  mytoken == "" ||
                  mytoken == null ||
                  this.getPaymentCardHolderName == "" ||
                  this.getPaymentCardExpiry == "" ||
                  this.getPaymentCardCvvNumber == ""
                ) {
                  this.$toastr.e("Card info is required");
                } else {
                  this.SubmitForm();
                }
              }
            }
          } else {
            this.loadingMessage =
              "please wait <br> Your appointment is being scheduled...";

            this.SubmitForm();
          }
        }
      } else {
        if (this.getPaymentType == "C") {
          this.loadingMessage =
            "please wait <br> While we process your payment...";
          if (mytoken == "" || mytoken == null) {
            this.$toastr.e(
              "The card number entered is not compliant, please check and re-enter."
            );
          } else {
            if (this.getIsHeartsPayment) {
              var expiremonth2 = document.getElementById("expiremonth")
                .innerHTML;
              var expireyear2 = document.getElementById("expireyear").innerHTML;

              if (
                mytoken == "" ||
                mytoken == null ||
                expiremonth2 == "" ||
                expiremonth2 == null ||
                expireyear2 == "" ||
                expireyear2 == null
                // this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitForm();
              }
            } else {
              if (
                mytoken == "" ||
                mytoken == null ||
                this.getPaymentCardHolderName == "" ||
                this.getPaymentCardExpiry == "" ||
                this.getPaymentCardCvvNumber == ""
              ) {
                this.$toastr.e("Card info is required");
              } else {
                this.SubmitForm();
              }
            }
          }
        } else {
          this.loadingMessage =
            "please wait <br> Your appointment is being scheduled...";
          this.SubmitForm();
        }
      }
    },
    timeFormat(value, date) {
      var fullDate = date + " " + value;
      return this.$moment(fullDate).format("hh:mm a");
    },
    SubmitForm() {
      this.errorMsg = "";
      let updateAbbreviationCode = "";
      if (this.getAbbreviationCode.data.length == 0) {
        updateAbbreviationCode = "";
      } else {
        updateAbbreviationCode = this.updateAbbreviationCode;
      }
      this.saveDataLoader = true;
      var mytoken = document.getElementById("mytoken");
      var formData = {
        type: this.getPaymentType,
        appointment_id: this.getNewAppNo.app_id,
        applicant_id: this.getNewAppNo.appt_id,
        abbreviation_code: updateAbbreviationCode,
        sms_status: false,
        appt_token: this.getNewAppNo.appt_token,
        service_ori: this.getServiceOri,

        time: {
          start: this.timeFormat(this.getNewAppNo.time, this.getNewAppNo.date)
        }
      };
      var invoice_details = [];

      var data_1 = {
        qty: this.getfClientRate.service.default_qty,
        is_additional: 0,

        title: "Service Name: " + this.getfClientRate.title
      };
      invoice_details.push(data_1);
      if (this.getAddQty > 0) {
        var data_2 = {
          qty: this.getAddQty,
          is_additional: 1,
          title: "Additional: Yes (" + this.getAddQty + ")"
        };
        invoice_details.push(data_2);
      }
      let token = mytoken.innerHTML;
      if (this.getPaymentType == "C") {
        if (this.getIsHeartsPayment) {
          var expiremonth = document.getElementById("expiremonth");
          var expireyear = document.getElementById("expireyear");
          var token_type = document.getElementById("token_type");
          var last_four = document.getElementById("last_four");
          var card_type = document.getElementById("card_type");
          formData.payment_details = {
            hearts_token: token,
            expire_month: expiremonth.innerHTML,
            expire_year: expireyear.innerHTML,
            card_cvv_no: this.getPaymentCardCvvNumber,
            postal: this.getPaymentCardPostalZip,
            token_type: token_type.innerHTML,
            last_four: last_four.innerHTML,
            card_type: card_type.innerHTML,
            card_address: this.getPaymentCardHolderAddress
          };
        } else {
          formData.payment_details = {
            card_number: token,
            card_holder_name: this.getPaymentCardHolderName,
            card_expiry: this.getPaymentCardExpiry,
            card_cvv_no: this.getPaymentCardCvvNumber,
            card_type: this.getPaymentCardType,

            card_address: this.getPaymentCardHolderAddress,
            card_city: this.getPaymentCardHolderCity,
            card_country: this.getPaymentCardHolderCountry,
            card_state: this.getPaymentCardHolderState,

            phone:
              this.getPaymentCardPhonecode.dial_code + this.getPaymentCardPhone,
            postal: this.getPaymentCardPostalZip
          };
        }
      }
      formData.invoice_details = invoice_details;

      this.$store
        .dispatch(STORE_APPOINTMENT_FROM_APPLICANT, {
          url:
            this.getPaymentType == "C"
              ? "appointment/applicant/payment"
              : "submit/appointment/applicant/",
          data: formData
        })
        .then(res => {
          if (this.getPaymentType != "C") {
            this.saveDataLoader = false;
            this.$toastr.s(res.msg);
            this.$store
              .dispatch(MAKE_INVOICE_AFTER_ONLINE_PAYEMENT, {
                appointment_id: this.getNewAppNo.app_id,
                invoice_details: invoice_details,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                appt_token: this.getNewAppNo.appt_token,
                card_token: "",
                invoice_id: res.invoice_id,
                service_ori: this.getServiceOri
              })
              .then(() => {
                this.saveDataLoader = false;

                // this.$toastr.s(data.msg);
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
              });
            this.reset();
            this.$router.go(-1);
          } else {
            this.$store
              .dispatch(MAKE_INVOICE_AFTER_ONLINE_PAYEMENT, {
                appointment_id: this.getNewAppNo.app_id,
                invoice_details: invoice_details,
                card_type: this.getPaymentCardType,
                card_number: this.getPaymentCardNumber
                  .replace(" ", "")
                  .slice(-4),
                appt_token: this.getNewAppNo.appt_token,
                card_token: "",
                invoice_id: res.invoice_id,
                service_ori: this.getServiceOri
              })
              .then(() => {
                this.saveDataLoader = false;

                // this.$toastr.s(data.msg);
              })
              .catch(errs => {
                this.errorMsg = errs.msg;
                this.saveDataLoader = false;
                // this.$toastr.e(errs.detail);
                this.errorMsg = errs.msg;
              });
            this.$toastr.s(res.msg);
            this.reset();
            this.$router.go(-1);
          }
        })
        .catch(errs => {
          if (errs.msg != "") {
            this.errorMsg = errs.msg;
            this.$toastr.e(errs.msg);
          }
          this.saveDataLoader = false;
        });
    },

    goback() {
      this.reset();
      if (this.current_page == 1) {
        this.$router.go(-1);
      } else {
        this.$store.commit(SET_F_CLIENT_RATE, {
          rate: "0",
          additional_rate: "0",
          service: {
            default_qty: 0
          }
        });
        this.$store.commit(SET_RATE_TOTAL_AMOUNT, 0);
        this.$store.commit(SET_ADD_RATE_QTY, 0);

        this.current_page = 1;
        let breadCrumbs_copy = [...this.breadCrumbs];
        breadCrumbs_copy.pop();
        this.breadCrumbs = breadCrumbs_copy;
        this.$store.commit(SET_BREADCRUMB, breadCrumbs_copy);
      }
    },
    updateOri(e) {
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, e.target.value);
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
      //     this.$store.commit(SET_CHECK_F_ABBREVIATION_CODE, {
      //   data: [],success:true
      // });
    },
    checkServiceOri() {
      if (this.updateServiceOriCode != "") {
        this.service_ori_code_error = true;
        var service_id = this.getServiceId;
        this.$store
          .dispatch(CHECK_F_SERVICE_ORI_CODE, {
            service_code: this.getServiceTypeCode,
            code: this.updateServiceOriCode,
            client: this.$route.params.id,
            service: service_id
          })
          .then(data => {
            if (data.success) {
              this.$store.commit(UPDATE_SERVICE_ORI_CODE, data.data.ori_number);
              this.$store.commit(UPDATE_ORI, data.data.service_ori_id);
              this.rateData = data.data;
              if (data.data.service.is_drug_testing) {
                this.qty = this.getQty;
                this.recipient = this.getMultirecipient;
              } else {
                this.qty = data.data.service.default_qty;
              }

              var rate = parseFloat(data.data.rate);
              var tax_percentage_amount = parseFloat(
                (
                  (parseFloat(data.data.tax_percentage) / 100) *
                  rate *
                  this.qty
                ).toFixed(2)
              );
              //  (rateData.additional_rate * getAddQty)
              //                   | taxAmount(rateData.tax_percentage)
              var tax_additional_amount =
                (parseFloat(data.data.tax_percentage) / 100) *
                data.data.additional_rate *
                this.getAddQty;
              this.$store.commit(
                SET_ADDITIONAL_CARD_TOTAL_AMOUNT,

                parseFloat(
                  data.data.additional_rate * this.getAddQty +
                    tax_additional_amount
                ).toFixed(2)
              );
              this.$store.commit(
                SET_RATE_TOTAL_AMOUNT,
                parseFloat(rate * this.qty + tax_percentage_amount).toFixed(2)
              );

              this.$store.commit(
                SET_TOTAL_SERVICE_AMOUNT,
                parseFloat(rate * this.qty + tax_percentage_amount).toFixed(2)
              );
              this.service_ori = "";
            } else {
              this.service_ori_code_error = true;
            }
          });
      } else {
        this.service_ori_code_error = true;
      }
    },
    removeServiceOri() {
      this.$store.commit(UPDATE_ORI, "");
      //  this.$store.commit(UPDATE_PAYMENT_TYPE,this.getPaymentType);
      this.service_ori_code_error = false;
      this.$store.commit(UPDATE_SERVICE_ORI_CODE, "");
      this.$store.commit(SET_CHECK_F_SERVICE_ORI_CODE, {
        data: "",
        success: "none"
      });
    },
    setServiceindex(index) {
      this.selected_index = index;
      this.fetchRate();
    },
    fetchRate() {
      this.loadingData = true;
      var service_id = this.getServiceId;
      if (this.updateServiceOriCode != "") {
        this.service_ori = this.updateServiceOriCode;
        this.checkServiceOri();
        this.loadingData = false;
      } else {
        this.$store
          .dispatch(NEW_APPOINTMENT, {
            app_id: this.$route.params.app_id,
            app_token: this.$route.params.token,
            service_id: service_id,
            reason: ""
          })
          .then(res => {
            if (res.code == 200) {
              this.$store
                .dispatch(FETCH_F_CLIENT_RATE, {
                  id: this.$route.params.id,
                  service_id: service_id
                })
                .then(data => {
                  this.rateData = data;
                  this.$store.commit(SET_F_CLIENT_RATE, data);
                  this.loadingData = true;
                  if (data.service.is_drug_testing) {
                    this.qty = this.getQty;
                    this.recipient = this.getMultirecipient;
                  } else {
                    this.qty = data.service.default_qty;
                  }
                  this.$store.commit(
                    SET_ENABLE_ORI_RATE,
                    data.service.enable_ori_rate
                  );
                  this.$store.commit(
                    SET_SERVICE_TYPE_CODE,
                    data.service.service_type_code
                  );
                  var rate = data.rate;

                  var tax_percentage_amount =
                    (parseFloat(data.tax_percentage) / 100) * rate * this.qty;
                  //  (rateData.additional_rate * getAddQty)
                  //                   | taxAmount(rateData.tax_percentage)
                  var tax_additional_amount =
                    (parseFloat(data.tax_percentage) / 100) *
                    data.additional_rate *
                    this.getAddQty;
                  this.$store.commit(
                    SET_ADDITIONAL_CARD_TOTAL_AMOUNT,

                    (
                      data.additional_rate * this.getAddQty +
                      tax_additional_amount
                    ).toFixed(2)
                  );

                  this.$store.commit(
                    SET_RATE_TOTAL_AMOUNT,

                    (rate * this.qty + tax_percentage_amount).toFixed(2)
                  );

                  this.$store.commit(
                    SET_TOTAL_SERVICE_AMOUNT,

                    (
                      rate * this.qty +
                      tax_percentage_amount +
                      parseFloat(this.getTotalAdditionalAmount)
                    ).toFixed(2)
                  );

                  let breadCrumbs_copy = [...this.breadCrumbs];
                  if (breadCrumbs_copy.length == 3) {
                    breadCrumbs_copy.push({
                      id: 4,
                      title: "Payment",
                      route: ""
                    });
                  }
                  this.breadCrumbs = breadCrumbs_copy;
                  this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);

                  this.current_page = 2;
                  this.loadingData = false;
                })
                .catch(() => {
                  this.loadingData = false;
                });
            }
          })
          .catch(() => {
            this.loadingData = false;
          });
      }
    },
    submitService() {
      if (this.getServiceTypeCode == "fdle") {
        if (this.getServiceOri == "") {
          if (this.getServiceOriCode.msg) {
            this.$toastr.e(this.getServiceOriCode.msg);
          } else {
            this.$toastr.e("Please confirm ORI");
          }
        } else {
          this.submit();
        }
      } else {
        this.submit();
      }
    },

    fetchServices() {
      this.dataLoading = true;
      let data = {
        client_id: this.$route.params.id,
        app_id: this.$route.params.app_id,
        app_token: this.$route.params.token
      };
      this.$store
        .dispatch(LOCATION_SERVICE, data)
        .then(data => {
          this.services = data.services;
          this.url = data.url;
          this.dataLoading = false;
        })
        .catch(() => {
          this.dataLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
// .alert-danger-dim {
// background: #f7cece90 !important;
// }
.bg-light-default {
  background-color: #ffffff;
}
.alert-custom {
  margin-top: 5%;
  z-index: 1;
  background: #dc3545 !important;
}

#book-footer-temp {
  .btn {
    height: 40px !important;
    float: right;
  }
}
</style>
<style scoped></style>
